<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    />
    <party-answer />

    <v-parallax
      height="600"
      src="https://resources.landcoo.com/30959419701329920.jpg"
    >
      <land-section
        id="noticeApp1"
        space="24"
      >
        <v-container>
          <v-row
            justify="space-between"
            align="start"
          >
            <v-col
              v-for="(not1, notIndex1) in notApps1"
              :key="`not1_${notIndex1}`"
              cols="12"
              md="6"
            >
              <div class="d-flex flex-column">
                <land-navigation
                  :title="not1.title"
                  :items="not1.buttons"
                  size="text-lg-h5"
                  color="red--text"
                  margin="mb-2"
                  @click="toNotices($event, not1)"
                />
                <land-notices
                  :items="not1.items"
                  back-color="transpart"
                  card-color="white"
                  button-color="error"
                  padding="py-2"
                  @click="onNotice($event, not1)"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </land-section>
    </v-parallax>

    <land-section
      id="noticeApp2"
      space="32"
      color="white"
    >
      <v-container>
        <v-row
          justify="space-between"
          align="start"
        >
          <v-col
            v-for="(not2, notIndex2) in notApps2"
            :key="`not2_${notIndex2}`"
            cols="12"
            md="6"
          >
            <div class="d-flex flex-column">
              <land-navigation
                :title="not2.title"
                :items="not2.buttons"
                size="text-lg-h5"
                margin="mb-2"
                color="red--text"
                @click="toNotices($event, not2)"
              />
              <land-notices
                :items="not2.items"
                back-color="transpart"
                card-color="grey lighten-4"
                button-color="error"
                padding="py-2"
                @click="onNotice($event, not2)"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>

    <land-contact-way
      dark
      back-color="blue-grey darken-4"
      assist-color="red"
    />

    <land-speed-dial
      :activator="speedDial.activator"
      :items="speedDial.items"
      @click="onSpeedDial"
    />
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import notice from '@/api/co.notice'
  import cookies from '@/api/co.libs.cookies'

  import mixSpeedDial from '@/pages/mixins/mix.speeddial'
  import mixPartyBanner from '@/pages/mixins/party/mix.party.banner'

  import partyPreset from '@/pages/mixins/party/mix.party.preset'

  export default {
    metaInfo: { title: '党建园地' },
    components: {
      PartyAnswer: () => import('@/pages/sections/PartyAnswer.vue')
    },
    mixins: [
      mixPartyBanner,
      mixSpeedDial
    ],
    data () {
      return {
        notJiuHao: {},
        notDangJian: {},
        notDongXun: {},
        notRunLian: {},
        notApps: [],
        notApps1: [],
        notApps2: []
      }
    },
    created () {
      this.speedDial.activator.color = 'red darken-2'
      this.speedDial.items.push(
        this.dialHome,
        this.dialQuestionnaire,
        this.dialComplaints,
        this.dialMaintain,
        this.dialReport
      )

      this.configApp()
      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initNoticesParams()
      this.loadNotices()
    },
    methods: {
      configApp () {
        const names = partyPreset.Names
        const parts = partyPreset.Parts

        this.notJiuHao = api.comm.deepClone(parts[names.JIU_HAO])
        this.notDangJian = api.comm.deepClone(parts[names.DANG_JIAN])
        this.notDongXun = api.comm.deepClone(parts[names.DONG_XUN])
        this.notRunLian = api.comm.deepClone(parts[names.RUN_LIAN])

        this.notApps1 = [
          this.notJiuHao,
          this.notDangJian
        ]
        this.notApps2 = [
          this.notDongXun,
          this.notRunLian
        ]
        this.notApps = [
          this.notJiuHao,
          this.notDangJian,
          this.notDongXun,
          this.notRunLian
        ]
      },
      onNotice (ev, part) {
        const notice = ev.item
        this.toNotice(part, notice)
      },
      toNotice (part, notice) {
        const params = {
          name: part.name,
          noticeId: notice.noticeId,
          type: notice.type,
          topicId: notice.topicId,
          categoryId: notice.categoryId,
        }
        const name = api.page.partyNotice.name
        cookies.set(name, JSON.stringify(params))
        this.$router.push({ name })
      },
      toNotices (ev, navigation) {
        if (!navigation) {
          return
        }

        const params = {
          name: navigation.name,
          title: navigation.title,
          type: navigation.type,
          topicId: navigation.topicId,
          categoryId: navigation.categoryId
        }
        const name = api.page.partyNotices.name
        cookies.set(name, JSON.stringify(params))
        this.$router.push({ name })
      },
      initNoticeParams (obj) {
        const executed = function (res) {
          // console.log('me.dataList: %o', me.dataList)
          if (res.status) {
          }
        }

        obj.params = notice.getParams({
          siteId: app.init.siteId,
          type: api.comm.NoticeTypes.NEWS,
          topicId: obj.topicId,
          status: 'publish',
          limit: obj.limit || 3,
          caches: obj.items,
          executed
        })
      },
      initNoticesParams () {
        for (const i in this.notApps) {
          const item = this.notApps[i]
          this.initNoticeParams(item)
        }
      },
      loadNotices (reload = true) {
        for (const i in this.notApps) {
          const item = this.notApps[i]
          item.params.reload = reload
          api.http.getItems(item.params)
        }
      },
    }
  }
</script>
